import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout/layout';
import { navigate } from "@reach/router";
import shareicon from '../assets/images/ic-share-arrow.svg';
import CF from '../components/commonfunctions';
import closeicon from '../assets/images/ic-close-black.svg';
import dsrecommend1 from '../assets/images/DS-recommend1.png';
import DS1 from '../assets/images/DS1.png';
import ReactHtmlParser from "react-html-parser";
import { formatDate } from '../utlis';
import ScrollAnimation from 'react-animate-on-scroll';
import Pagination from 'rc-pagination';
import Search from '../components/search';
import ScaleLoader from "react-spinners/ScaleLoader";
import { share_url } from '../config/configs'
import { isLoggedIn } from "../services/auth";
import { DIZZEE_API, DIZZEE_LOGIN, S3_URL } from "gatsby-env-variables";
import { window } from 'browser-monads';
import axios from "axios";

import categoryOneIcon from '../assets/images/ic-whats-new.svg';
import categoryTwoIcon from '../assets/images/ic-features.svg';
import categoryThreeIcon from '../assets/images/ic-tutorials.svg';
import showAllIcon from '../assets/images/ic-show-all.svg';
import searchIcon from '../assets/images/ic-search.svg';
import resourcesIcon from '../assets/images/ic-resources.svg';
import subscribeIcon from '../assets/images/ic-subscribe.svg';

const navMenuArray = [categoryOneIcon,categoryTwoIcon,categoryThreeIcon,showAllIcon,searchIcon,resourcesIcon,subscribeIcon];

// const dizzeeLogin = `${DIZZEE_LOGIN}&client=umx&redirect=${encodeURIComponent(window.location.href)}`;

export const query = graphql`
{
  WordPress {
    pageBy(uri: "ds") {
      title
      ds_page {
        recommendedSectionTitle
        selectRecommended {
          __typename
          ... on WordPress_Post {
            title
            slug
            uri
            date
            featuredImage {
              node {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    }
    menu(id: "dGVybToxOQ==") {
      name
      menuItems {
        nodes {
          label
          cssClasses
          menu_data {
            externalLink
            sectionId
            svg {
              sourceUrl
            }
          }
        }
      }
    }
  }
  }
  `
let pageSize = 16;
const DS = ({ data }) => {
  const windowGlobal = typeof window !== 'undefined' && window;
  const [isSticky, setSticky] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [scrollOffset, setOffset] = useState(0);
  const [viewAll, setViewAll] = useState(false);
  const [disclamier, setDisclamier] = useState(true);
  const [list, listData] = useState([]);
  const [pageListAllData, setPageListAllData] = useState([]);
  const [page, newPage] = useState(1);
  const [total, newTotal] = useState();
  const [user, setUser] = useState();
  const [disableBlog, setDisableBlog] = useState(true);
  const handleScroll = (e) => {
    var headerH = document.querySelector('header') ? document.querySelector('header').offsetHeight : ''
    setSticky(window.scrollY > headerH ? true : false)
  };

  const settingDisclamier = () => {
    setDisclamier(false);
    CF.setItem('disclamier', false);
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    getListDataApi();
    // pagination();
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!user && isLoggedIn()) {
      axios.get(`${DIZZEE_API}/action/user/data?apps=all`, {
        withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            application: "swiftTrends"
          },
        }).then((resp) => {
          if (resp.data.info === "SUCCESSFULLACTION") {
            setUser(resp.data.userInfo);
          }
        }).catch(error => {
          if (error.response) {
            console.log('error', error.response.data);
          }
        });
    }
  }, []);

  const handleCategory = (e, label) => {
    if (label == "Search") {
      setIsopen(!isOpen)
    }
    else if (label == "Resources") {
      navigate('/resources')
    }
    else if (label == 'Subscribe') {
      e.preventDefault();
      e.stopPropagation();
      const scrollSec = document.querySelector(e.target.hash);
      const headerOffset = document.getElementsByClassName("category-menu-outer") && document.getElementsByClassName("category-menu-outer")[0].clientHeight;
      const offsetH = (scrollSec.offsetTop - (!isSticky ? 108 + headerOffset : 108));
      setOffset(offsetH)
      window.scrollTo({ behavior: "smooth", top: offsetH });
    }
    else if (label === 'Show All') navigate('/showall')
    else if (label === "Category 1") navigate('/category1')
    else if (label === "Category 2") navigate('/category2')
    else if (label === "Category 3") navigate('/category3')
  }

  const getListDataApi = async () => {

    let data = await fetch(S3_URL +`/ds.json`)
    .then(response=>{
      return response.json()
    })
    let dataArray = [...data]
    setPageListAllData(data);
    let slicedArray = dataArray.slice(0, pageSize);
    listData(slicedArray)
    newPage(1);
    newTotal(Number(data.length))
  }

  const pagination = async (e) => {

    let dataArray = [...pageListAllData]
    let slicedArray = dataArray.slice(pageSize*(e-1), pageSize*e);
    listData(slicedArray)
    newPage(e ? e : 1);
  }

  let title = data.WordPress.pageBy && data.WordPress.pageBy.title
  let recommendedSectionTitle = data.WordPress.pageBy && data.WordPress.pageBy.ds_page && data.WordPress.pageBy.ds_page.recommendedSectionTitle
  let selectRecommended = data.WordPress.pageBy && data.WordPress.pageBy.ds_page && data.WordPress.pageBy.ds_page.selectRecommended
  let menuArr = data.WordPress.menu && data.WordPress.menu.menuItems && data.WordPress.menu.menuItems.nodes
if (isLoggedIn(user) && !disableBlog) {
  return (
    <React.Fragment>
      {list && list.length && Array.isArray(list) ?
        <Layout uma="inner-page" headerColor="header-DS" title={"DS"} seoTitle={'Data stories'} seoDes={"x-musix data stories page"}>
          <div className={`category-menu-outer ds-category-menu-outer ${isSticky ? 'header-category-fixed' : ''}`}>
            <div className="container">
              {/********************* Category Menu Start *********************/}
              <nav className="category-menu">
                <ul>
                  {menuArr && menuArr.length ? menuArr.map((menu, key) => {
                    return (<li className="seven-category" key={key} onClick={(e) => handleCategory(e, menu.label)} >
                      <a id="responsive-menu-trigger" href={menu && menu.label === 'Subscribe' ? '#conatctus' : menu.label === "Search" && "#" }>
                        <span className="category-img">
                          <img src={navMenuArray[key]} alt={menu.label} />
                        </span>
                        <span className="category-name">{menu.label}</span>
                      </a>
                    </li>)
                  }) : null}
                </ul>
              </nav>
              {/********************* Category Menu End *********************/}
            </div>
          </div>

          <Search />

          <section className="common-banner ds-banner">
            <div className="container">
              <div className="common-banner-content">
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <h1>{title}</h1>
                </ScrollAnimation>
              </div>
              {/********************* Data Stories Listing Start *********************/}
              <div className="data-story-listing">
                {/********************* Notification start *********************/}
                {(disclamier && CF.getItem('disclamier') !== 'false') ? <div className="notify">
                  <p>This content is intended for internal use only and shall not be distributed outside of Universal Music Group.</p>
                  <a className="close-notify" href="javascript:;" onClick={settingDisclamier}><img src={closeicon} alt="Close" /></a>
                </div> : ""}

                {/********************* Notification end *********************/}
                <div className="row">
                  {/********************* Data story block start *********************/}
                  {
                    list && list.length ? list.map((post, key) => {
                      let desc = post.content && post.content.rendered ? ReactHtmlParser(post.content.rendered) : '';
                      let image = post && post._embedded && post._embedded['wp:featuredmedia'][0].source_url;
                      if (key < 4 || viewAll) {
                        return (
                          <div className="col-md-6" key={key} >
                            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                              <div className="data-story-card" style={{ backgroundImage: image ? `url(${image})` : `url(${DS1})` }}>
                                <a className="share-icon" href={"mailto:?Subject=X music&Body=" + share_url + `/ds-details?slug=${post.slug}`}>
                                  <img src={shareicon} alt="Share" />
                                </a>
                                <div className="title-date">
                                  <span className="date">{formatDate(post.date, "MM/DD/YYYY")}</span>
                                  <h6>{post && post.title && post.title.rendered}</h6>
                                </div>
                                <div className="data-story-card-overaly">
                                  <a href="javascript:;" onClick={() => navigate(`/ds-details/?slug=${post.slug}`)} >
                                    <div className="data-story-card-overaly-content">
                                      <span className="date">{formatDate(post.date)}</span>
                                      <h6>{post && post.title && post.title.rendered}</h6>
                                      {desc ? desc : ""}
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </ScrollAnimation>
                          </div>
                        )
                      }
                    }) : null
                  }
                </div>
                {list && list.length >= 4 && !viewAll ? <div className="view-all-sec">
                  <a href="javascript:;" className="view-all-btn" onClick={() => setViewAll(true)}>{viewAll === false ? 'VIEW ALL' : ''}</a>
                </div> : ""}
              </div>

              {viewAll ? <div className="row">
              <div className="col-md-12">
                <Pagination showLessItems pageSize={pageSize} current={page} total={total} onChange={(e) => pagination(e)} locale={true} />
              </div>
            </div> : ""}
              {/********************* Data Stories Listing End *********************/}
            </div>
          </section>
          {/* Recommned section start */}
          <section className="common-whatsnew recommend-section bg-main">
            <div className="container">
              <div className="common-whatsnew-content">
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <h3 className="color-white">{recommendedSectionTitle ? recommendedSectionTitle : ''}</h3>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <div className="whats-new-block-outer" >
                    {/********************* Recommend Start *********************/}
                    {selectRecommended && selectRecommended.length ? selectRecommended.map((recommend, key) => {

                      let image = recommend.featuredImage && recommend.featuredImage.node && recommend.featuredImage.node.sourceUrl
                      let slug = recommend.slug && recommend.slug;
                      return (
                        <div id="whatsabout" className="whats-new-block recommend-block ds-recommend-block" key={key} onClick={() => navigate(`/ds-details/?slug=${slug}`)} >
                          <div className="whats-new-img" style={{ backgroundImage: image ? `url(${image})` : `url(${dsrecommend1})` }}>
                          </div>
                          <h6>{recommend.title}</h6>
                        </div>
                      )
                    }) : null
                    }
                    {/********************* Recommend End *********************/}
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </section>
          {/* Recommned section End */}
        </Layout> :
        <div className="product-loader"><ScaleLoader color={'#141618'} size={150} /></div>}

    </React.Fragment >
  );
} else if (!isLoggedIn() || disableBlog) {
  navigate('/');
  return null;
}
};


export default DS
